.layout-footer {
    @include transition(margin-left $transitionDuration);
    background-color: $footerBgColor;
    padding: 1em 2em;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;

    img {
        vertical-align: middle;
    }

    .footer-text {
        vertical-align: middle;
        line-height: 36px;
    }
}
